import React, { useState } from 'react'

import { Card, Col, Container, Row } from 'react-bootstrap'
import FairyTaleAlert from '../../components/fairytale-alert'
import FairytaleFormComponent from '../../components/fairytale-form'
import fairytaleYup from '../../yup'

import { useEditSettingMutation } from '../../redux/appQuery'
import useSettingFormData from './useSettingFormData'
import { ICreateSetting } from '../../types/settings'

export default function ProxySetting() {
  const SettingFormData = useSettingFormData()
  const [editSetting] = useEditSettingMutation()
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [alertVariant, setAlertVariant] = useState<'success' | 'danger'>(
    'success',
  )
  const APP_CREATE_SCHEMA = fairytaleYup.object().shape({
    PROXY_URL: fairytaleYup.string().max(100).required(),
  })

  const _handleSubmit = async (values: ICreateSetting[]) => {
    const result = (await editSetting(values)) as { data: any }
    if (result.data) {
      setShowAlert(true)
      setAlertVariant('success')
      setAlertMessage('Successfully Saved.')
      setTimeout(() => setShowAlert(false), 10000)
    }
  }

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Settings</h1>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <FairytaleFormComponent
                  formProps={SettingFormData}
                  editMode={false}
                  onSubmit={_handleSubmit}
                  formSchema={APP_CREATE_SCHEMA}
                  initialFormValues={{}}
                />
                <FairyTaleAlert
                  variant={alertVariant}
                  message={alertMessage}
                  heading=""
                  show={showAlert}
                  name="app-create"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}
