import React, { MouseEventHandler, useState } from 'react'

import { Card, Col, Container, Row } from 'react-bootstrap'
import { ArrowLeftCircle, Edit, Trash } from 'react-feather'
import FairyTaleTable from '../../components/fairytale-table'
import FairyTaleAlert from '../../components/fairytale-alert'
import FairytaleFormComponent from '../../components/fairytale-form'

import { Column } from 'react-table'
import { Ad, AdCreate, Campaign } from '../../types/ads'
import useFairyTaleClubAdsCrudHooks from '../../hooks/useFairyTaleClubAppAdsCrudHooks'
import {
  AD_FORM_INITIAL_VALUES,
  CREATE_ADS_FORM_ATTRIB_DATA,
} from './constants'
import fairytaleYup from '../../yup'
import { useTranslation } from 'react-i18next'
import {
  useDeleteAppCampaignAdMutation,
  useGetAppCampaignAdsQuery,
} from '../../redux/appQuery'
import FairytaleModal from '../../components/fairytale-modal'
import EditAd from './EditAd'

interface ICampaignDetail {
  onClickBack: MouseEventHandler<SVGElement>
  campaign: Campaign
}

interface IDetailsDisplayCard {
  mainTitle: string
  subTitle?: string
  bodyText?: string
  icon?: React.JSX.Element
  bgColor?: string
}

export interface IMonthlyStatObject {
  clicksPerMonth: number[]
  viewsPermonth: number[]
}

const DetailsDisplayCard: React.FC<IDetailsDisplayCard> = ({
  mainTitle,
  subTitle,
  bodyText,
  bgColor,
}) => {
  return (
    <Col>
      <Card
        style={{ backgroundColor: `${bgColor}` }}
        className="ads-display-card"
      >
        <Card.Header as="h6">{mainTitle}</Card.Header>
        <Card.Body>
          <Card.Title as="p">{subTitle}</Card.Title>
          <Card.Text>{bodyText}</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  )
}

const CampaignDetail: React.FC<ICampaignDetail> = ({
  onClickBack,
  campaign,
}) => {
  const { t: tcommon } = useTranslation(['common'])
  const { t: tforms } = useTranslation(['formFields'])
  const { t: tpagetexts } = useTranslation(['pageTexts'])
  const modalButtonText = tcommon('yes')
  const modalButtonNegText = tcommon('cancel')
  const { is_active, title, description, ad_duration, id } = campaign
  const { createAppCampaignAd } = useFairyTaleClubAdsCrudHooks(campaign.app_id)
  const [deleteAppCampaignAd] = useDeleteAppCampaignAdMutation()
  const { data: campaignAds, isLoading: campaignAdsLoading } =
    useGetAppCampaignAdsQuery(campaign.id)
  const stats: any[] = []
  const [alertVariant, setAlertVariant] = useState<
    'light' | 'success' | 'danger'
  >('light')
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false)
  const [rowData, setRowData] = useState<Ad | null>(null)
  const [modalMessage, setModalMessage] = useState<string>('')
  const [modalHeading, setModalHeading] = useState<string>('')
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [feedToDelete, setFeedToDelete] = useState<number | null>(null)

  const AD_SCHEMA = fairytaleYup.object().shape({
    link: fairytaleYup.string().required(tforms('fieldIsRequired')),
    image_link: fairytaleYup.string().required(tforms('fieldIsRequired')),
  })

  const AD_TABLE_COLUMNS: Column<Record<string, unknown>>[] = React.useMemo(
    () => [
      {
        Header: 'adLinkLabel',
        id: 'link',
        Cell: ({ row }: { row: any }) => {
          let { link } = row.original
          return link
        },
      },
      {
        Header: 'adImageLinkLabel',
        id: 'image_link',
        Cell: ({ row }: { row: any }) => {
          let { image_link } = row.original
          return image_link
        },
      },
      {
        Header: 'Last update',
        id: 'updatedAt',
        Cell: ({ row }: { row: any }) => {
          let { updatedAt } = row.original
          return new Date(updatedAt).toLocaleDateString()
        },
      },
      {
        Header: tpagetexts('deleteCampaign'),
        id: 'actions',
        Cell: ({ row }: { row: any }) => (
          <span>
            <Edit
              className="align-middle mx-3 cursor-pointer"
              size={18}
              data-cy={`campaign-list-actions-edit-${row.index}`}
              onClick={() => _handleAdTableRowClick(row.original)}
            />
            <Trash
              className="align-middle mx-2 cursor-pointer"
              size={18}
              data-cy={`campaign-list-actions-delete-${row.index}`}
              onClick={() =>
                _handleShowModalAndDeleteFeedData(
                  `Delete Ad ${row.original.link}?`,
                  `Delete Ad ${row.original.link}`,
                  row.original,
                )
              }
            />
          </span>
        ),
      },
    ],
    [tpagetexts],
  )
  const _handleShowModalAndDeleteFeedData = async (
    message: string,
    heading: string,
    rowValues: Ad,
  ) => {
    setModalMessage(message)
    setModalHeading(heading)
    setShowModal(true)
    setShowSpinner(true)
    setFeedToDelete(rowValues.ad_id)
  }
  const _handleCloseModal = () => {
    setModalMessage('')
    setModalHeading('')
    setShowModal(false)
    if (showSpinner) setShowSpinner(false)
  }
  const _handleShowModal = (message: string, heading: string) => {
    setModalMessage(message)
    setModalHeading(heading)
    setShowModal(true)
  }
  const _handleDeleteFeed = async (id: number) => {
    try {
      await deleteAppCampaignAd(id)
      setShowSpinner(false)
      _handleCloseModal()
    } catch (error) {
      _handleShowModal('Unexpected error! Try again soon', 'Unexpected Error')
    }
  }

  const _handleSubmit = async (
    values: AdCreate,
    { setStatus, setErrors, setSubmitting, resetForm }: any,
  ) => {
    values.campaign = campaign.id
    console.log('Submit Ads create form', values)
    const result = (await createAppCampaignAd(values)) as { data: any }
    console.log('Submit Ads create form result ', result)
    if (result.data) {
      setAlertVariant('success')
      setAlertMessage(tforms('feedCreateSuccess'))
      setShowAlert(true)
      setTimeout(() => setShowAlert(false), 5000)
      resetForm()
    } else {
      // @ts-ignore
      const message = result.error.data.message
      setStatus({ success: false })
      setErrors({ submit: message })
      setSubmitting(false)
      setAlertVariant('danger')
      setAlertMessage(tforms('somethingWentWrong'))
      setShowAlert(true)
      setTimeout(() => setShowAlert(false), 5000)
    }
  }

  const _handleAdTableRowClick = (row: any) => {
    setRowData(row)
    setShowDetailModal(true)
  }

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <ArrowLeftCircle
          className="cursor-pointer mb-3"
          onClick={onClickBack}
        />
        <Row>
          <Card>
            <Card.Body>
              <div className="mb-3">
                <h3>{title}</h3>
                <p>{description}</p>
                {is_active ? (
                  <p>
                    {`Campaign is `}
                    <b>active</b>
                  </p>
                ) : (
                  <p>
                    {`Campaign is `}
                    <b>not active</b>
                  </p>
                )}
              </div>
            </Card.Body>
          </Card>
        </Row>
        <Row>
          <DetailsDisplayCard
            mainTitle="Ads duration"
            subTitle="Total time in secs for each ad in this campaign to run"
            bodyText={ad_duration.toString() + ' seconds'}
          />
          <DetailsDisplayCard
            mainTitle="Number of Ads"
            subTitle="Total number ads in this campaign"
            bodyText={campaignAdsLoading ? 0 : campaignAds.length.toString()}
          />
          <DetailsDisplayCard
            mainTitle="Clicks"
            subTitle="Total number of times all ads in this campaign have been clicked"
            bodyText={stats.reduce((a, b) => a + b.clicks.length, 0).toString()}
          />
          <DetailsDisplayCard
            mainTitle="Views"
            subTitle="Total number of times all ads in this campaign have been viewed "
            bodyText={stats.reduce((a, b) => a + b.views.length, 0).toString()}
          />
        </Row>
        <Row>
          <Col>
            <Row>
              <FairyTaleTable
                columns={AD_TABLE_COLUMNS}
                data={campaignAdsLoading ? [] : campaignAds}
                showHeader={true}
                mainTitle="Ads"
                subtitle={`${
                  campaignAdsLoading ? 0 : campaignAds.length.toString()
                } ads in this campaign`}
              />
            </Row>
            <Row>
              <Card>
                <Card.Body>
                  <h3>Create new ad</h3>
                  <FairytaleFormComponent
                    formProps={CREATE_ADS_FORM_ATTRIB_DATA}
                    editMode={false}
                    onSubmit={_handleSubmit}
                    initialFormValues={AD_FORM_INITIAL_VALUES}
                    formSchema={AD_SCHEMA}
                  />
                  <FairyTaleAlert
                    variant={alertVariant}
                    message={tpagetexts(alertMessage)}
                    heading=""
                    show={showAlert}
                    name="feed-create"
                  />
                </Card.Body>
              </Card>
            </Row>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <div className="mb-3">
                  <p>Analytics here, coming soon!</p>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <FairytaleModal
        showModal={showDetailModal}
        onHideModal={() => setShowDetailModal(false)}
        name="user-details-modal"
      >
        {rowData && <EditAd ad={rowData} campaignId={id} />}
      </FairytaleModal>
      <FairytaleModal
        showModal={showModal}
        onHideModal={_handleCloseModal}
        modalHeading={modalHeading}
        modalMessage={modalMessage}
        onClickNegativeButton={_handleCloseModal}
        onClickPositveButton={() =>
          feedToDelete && _handleDeleteFeed(feedToDelete)
        }
        modalButtonTextPositive={modalButtonText}
        modalButtonTextNegative={modalButtonNegText}
        name="feeds"
      />
    </React.Fragment>
  )
}

export default CampaignDetail
