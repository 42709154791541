import React, { useState } from 'react'

import { Row } from 'react-bootstrap'
import { Eye, Trash, Edit } from 'react-feather'
import FairyTaleTable from '../../components/fairytale-table'
import CampaignDetail from './CampaignDetail'

import { IApp } from '../../types/app'
import { Campaign, Ad, AdView, AdClick, Media } from '../../types/ads'
import { Column } from 'react-table'

import { useTranslation } from 'react-i18next'

import CreateNewCampaign from './CreateCampaign'
import FairytaleModal from '../../components/fairytale-modal'
import useFairyTaleClubAdsCrudHooks from '../../hooks/useFairyTaleClubAppAdsCrudHooks'
import EditCampaign from './EditCampaign'

interface IFairytaleAds {
  app: IApp
  campaigns: Campaign[]
  isUser?: boolean
}

interface ICampaignDetailsStat {
  id: number
  views: Array<AdView>
  clicks: Array<AdClick>
  media: Media
}

export interface ICampaignDetails {
  campaign: Campaign
  ads: Ad[]
  stats: ICampaignDetailsStat[]
}

const FairytaleAdsPage: React.FC<IFairytaleAds> = ({
  app,
  campaigns,
  isUser = false,
}) => {
  const { t: tcommon } = useTranslation(['common'])
  const { t: tpagetexts } = useTranslation(['pageTexts'])
  const [rowData, setRowData] = useState<Campaign | null>(null)
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false)
  const modalButtonText = tcommon('yes')
  const modalButtonNegText = tcommon('cancel')
  const pageTitle = 'All Campaigns'
  const [activeRow, setActiveRow] = useState<Campaign | null>(null)
  const [modalMessage, setModalMessage] = useState<string>('')
  const [modalHeading, setModalHeading] = useState<string>('')
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [feedToDelete, setFeedToDelete] = useState<Campaign | any>(null)
  const { deleteAppCampaign } = useFairyTaleClubAdsCrudHooks(app.app_id)

  const CAMPAIGN_TABLE_COLUMNS: Column<Record<string, unknown>>[] =
    React.useMemo(
      () => [
        {
          Header: 'campaignTitle',
          id: 'title',
          Cell: ({ row }: { row: any }) => {
            let { title } = row.original
            return title
          },
        },
        {
          Header: 'camapignDescription',
          id: 'description',
          Cell: ({ row }: { row: any }) => {
            let { description } = row.original
            return description
          },
        },
        {
          Header: 'campaignViews',
          id: 'views',
          Cell: ({ row }: { row: any }) => {
            return 5678
          },
        },
        {
          Header: 'campaignClicks',
          id: 'clicks',
          Cell: ({ row }: { row: any }) => {
            return 456
          },
        },
        {
          Header: 'Status',
          id: 'is_active',
          Cell: ({ row }: { row: any }) => {
            let { is_active } = row.original
            return is_active ? tcommon('active') : tcommon('inActive')
          },
        },
        {
          Header: tpagetexts('deleteCampaign'),
          id: 'actions',
          Cell: ({ row }: { row: any }) => (
            <span>
              <Eye
                className="align-middle mx-3 cursor-pointer"
                size={18}
                data-cy={`campaign-list-actions-edit-${row.index}`}
                onClick={() => setActiveRow(row.original)}
              />
              <Edit
                className="align-middle mx-3 cursor-pointer"
                size={18}
                data-cy={`campaign-list-actions-edit-${row.index}`}
                onClick={() => _handleShowFeed(row.original)}
              />
              <Trash
                className="align-middle mx-2 cursor-pointer"
                size={18}
                data-cy={`campaign-list-actions-delete-${row.index}`}
                onClick={() =>
                  _handleShowModalAndDeleteFeedData(
                    `Delete Campaign ${row.original.title}?`,
                    `Delete Campaign ${row.original.title}`,
                    row.original,
                  )
                }
              />
            </span>
          ),
        },
      ],
      [tcommon, tpagetexts],
    )

  const _handleShowFeed = (rowValues: Campaign) => {
    setRowData(rowValues)
    setShowDetailModal(true)
  }

  const _handleShowModal = (message: string, heading: string) => {
    setModalMessage(message)
    setModalHeading(heading)
    setShowModal(true)
  }

  const _handleShowModalAndDeleteFeedData = async (
    message: string,
    heading: string,
    rowValues: Campaign,
  ) => {
    setModalMessage(message)
    setModalHeading(heading)
    setShowModal(true)
    setShowSpinner(true)
    setFeedToDelete(rowValues)
  }

  const _handleCloseModal = () => {
    setModalMessage('')
    setModalHeading('')
    setShowModal(false)
    if (showSpinner) setShowSpinner(false)
  }

  const _handleDeleteFeed = async (campaignToDelete: Campaign) => {
    try {
      await deleteAppCampaign(campaignToDelete)
      setShowSpinner(false)
      _handleCloseModal()
    } catch (error) {
      _handleShowModal('Unexpected error! Try again soon', 'Unexpected Error')
    }
  }

  return (
    <React.Fragment>
      <h1 className="h3 mb-3">{activeRow === null ? pageTitle : null}</h1>
      <Row>
        {activeRow === null ? (
          <>
            <FairyTaleTable
              data-cy="ads-table-main"
              columns={CAMPAIGN_TABLE_COLUMNS}
              data={campaigns.length > 0 ? campaigns : []}
              showHeader={false}
              mainTitle=""
              subtitle={`${campaigns ? campaigns.length : 0} Campaigns`}
              useSearchFilter={isUser}
              selectOptions={[
                {
                  value: app.app_title,
                  label: app.app_title,
                },
              ]}
              onChangeSelect={null}
            />
            <FairytaleModal
              showModal={showDetailModal}
              onHideModal={() => setShowDetailModal(false)}
              name="user-details-modal"
            >
              {rowData && <EditCampaign campaign={rowData} />}
            </FairytaleModal>
            <FairytaleModal
              showModal={showModal}
              onHideModal={_handleCloseModal}
              modalHeading={modalHeading}
              modalMessage={modalMessage}
              onClickNegativeButton={_handleCloseModal}
              onClickPositveButton={() => _handleDeleteFeed(feedToDelete)}
              modalButtonTextPositive={modalButtonText}
              modalButtonTextNegative={modalButtonNegText}
              name="feeds"
            />
          </>
        ) : (
          <CampaignDetail
            onClickBack={() => setActiveRow(null)}
            campaign={activeRow}
          />
        )}
      </Row>
      <Row>{activeRow === null ? <CreateNewCampaign app={app} /> : null}</Row>
    </React.Fragment>
  )
}

export default FairytaleAdsPage
