import { IFormAtributes } from '../../types/feed'

export const FEED_FORM_ATTRIB_DATA: Array<IFormAtributes> = [
  {
    type: 'text',
    name: 'feed_name',
    required: true,
    placeholder: 'feedNamePlaceholder',
    visible: true,
    label: 'feedName',
    translateText: 'feedName',
    value: '',
    disabled: false,
  },
  {
    type: 'text',
    name: 'push_content',
    required: false,
    placeholder: 'feedContentPlaceholder',
    visible: true,
    label: 'feedContent',
    translateText: 'feedContent',
    value: '',
    disabled: false,
  },
  {
    type: 'text',
    name: 'push_title',
    required: false,
    placeholder: 'feedContentTitlePlaceholder',
    visible: true,
    label: 'feedContentTitle',
    translateText: 'feedContentTitle',
    value: '',
    disabled: false,
  },
  {
    type: 'text',
    name: 'url',
    required: true,
    placeholder: 'feedUrlPlaceholder',
    visible: true,
    label: 'feedUrl',
    translateText: 'feedUrl',
    value: '',
    disabled: false,
  },
  {
    type: 'select',
    name: 'integration_type',
    required: true,
    placeholder: 'feedIntegrationTypesPlaceholder',
    visible: true,
    label: 'feedIntegrationTypes',
    translateText: 'feedIntegrationTypes',
    value: '',
    disabled: false,
  },
  {
    type: 'text',
    name: 'icon_url',
    required: false,
    placeholder: 'feedIconUrlPlaceholder',
    visible: true,
    label: 'feedIconUrl',
    translateText: 'feedIconUrl',
    value: '',
    disabled: false,
  },
  {
    type: 'text',
    name: 'custom_icon_url',
    required: false,
    placeholder: 'feedCustomIconUrlPlaceholder',
    visible: true,
    label: 'feedCustomIconUrl',
    translateText: 'feedCustomIconUrl',
    value: '',
    disabled: false,
  },
  {
    type: 'text',
    name: 'apikey',
    required: true,
    placeholder: 'feedApiKeyPlaceholder',
    visible: true,
    label: 'feedApiKey',
    translateText: 'feedApiKey',
    value: '',
    disabled: false,
  },
  {
    type: 'checkbox',
    name: 'enabled',
    required: false,
    placeholder: 'feedEnabledPlaceholder',
    visible: true,
    label: 'feedEnabled',
    translateText: 'feedEnabled',
    value: '',
    disabled: false,
  },
  {
    type: 'checkbox',
    name: 'push_notification_enabled',
    required: false,
    placeholder: 'feedPushEnabledPlaceholder',
    visible: true,
    label: 'feedPushEnabled',
    translateText: 'feedPushEnabled',
    value: '',
    disabled: false,
  },
  {
    type: 'checkbox',
    name: 'show_in_news',
    required: false,
    placeholder: 'showInNewsPlaceholder',
    visible: true,
    label: 'feedShowInNews',
    translateText: 'feedShowInNews',
    value: '',
    disabled: false,
  },
  {
    type: 'checkbox',
    name: 'show_in_comm_comp',
    required: false,
    placeholder: 'feedShowInCommCompPlaceholder',
    visible: true,
    label: 'feedShowInCommComp',
    translateText: 'feedShowInCommComp',
    value: '',
    disabled: false,
  },
  {
    type: 'checkbox',
    name: 'use_proxy',
    required: false,
    placeholder: 'useProxy',
    visible: true,
    label: 'useProxy',
    translateText: 'useProxy',
    value: '',
    disabled: false,
  },
]
