const common_fi = {
  //Generic
  for: 'varten',
  thereAre: 'On',
  page: 'Sivu',
  show: 'Näytä:',
  goToPage: 'Mene sivulle:',
  submit: 'Lähetä',
  select: '--- Valitse ---',
  Actions: 'Toiminnot',
  of: '/',
  yes: 'Kyllä',
  no: 'Ei',
  cancel: 'Peruuttaa',
  enabled: 'Käytössä',
  disabled: 'Liikuntarajoitteinen',
  active: 'aktiivinen',
  inActive: 'Epäaktiivinen',
}

export default common_fi
