import { useGetSettingsQuery } from '../../redux/appQuery'

const useSettingFormData = () => {
  const { data: settings = [] } = useGetSettingsQuery({})
  return [
    {
      type: 'text',
      name: 'PROXY_URL',
      required: true,
      placeholder: 'PROXY URL',
      visible: true,
      label: 'PROXY URL',
      translateText: 'PROXY_URL',
      disabled: false,
      value:
        settings.find((setting: any) => setting.key === 'PROXY_URL')?.value ??
        '',
    },
  ]
}

export default useSettingFormData
