const common_se = {
  //Generic
  for: 'för',
  thereAre: 'Det finns',
  page: 'Sida',
  show: 'Show:',
  goToPage: 'Gå till sidan:',
  submit: 'Skicka in',
  select: '--- Välj ---',
  Actions: 'Åtgärder',
  of: 'av',
  yes: 'Ja',
  no: 'Nej',
  cancel: 'Avbryt',
  enabled: 'Aktiverad',
  disabled: 'Liikuntarajoitteinen',
  active: 'aktiva',
  inActive: 'Inaktiv',
}

export default common_se
