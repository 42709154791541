import { Col, Tab, Tabs } from 'react-bootstrap'

import ProxySetting from './ProxySetting'
import GlobalAccessToken from './GlobalAccessToken'

export default function Settings() {
  return (
    <Col>
      <Tabs
        defaultActiveKey="proxy"
        id="justify-tab-example"
        className="mb-3"
        data-cy="app-detail-tab-menu"
      >
        <Tab
          eventKey="proxy"
          title="Proxy"
          data-cy="app-detail-tab-menu-item-basicInfo"
        >
          <ProxySetting />
        </Tab>
        <Tab
          eventKey="global_token"
          title="Global Token"
          data-cy="app-detail-tab-menu-item-token"
        >
          <GlobalAccessToken />
        </Tab>
      </Tabs>
    </Col>
  )
}
