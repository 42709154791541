import React, { useState } from 'react'

import { Card, Col, Container, Row } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'
import FairyTaleAlert from '../../components/fairytale-alert'
import { CREATE_ADS_FORM_ATTRIB_DATA } from './constants'
import FairytaleFormComponent from '../../components/fairytale-form'
import { Ad } from '../../types/ads'
import fairytaleYup from '../../yup'
import { useEditAppCampaignAdMutation } from '../../redux/appQuery'

export default function EditAd({
  ad,
  campaignId,
}: {
  ad: Ad
  campaignId: number
}) {
  const { t: tforms } = useTranslation(['formFields'])
  const { t: tpagetexts } = useTranslation(['pageTexts'])
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [editAppCampaignAd] = useEditAppCampaignAdMutation()

  const AD_SCHEMA = fairytaleYup.object().shape({
    link: fairytaleYup.string().required(tforms('fieldIsRequired')),
    image_link: fairytaleYup.string().required(tforms('fieldIsRequired')),
  })

  const _handleSubmit = async (
    values: Ad,
    { setStatus, setErrors, setSubmitting }: any,
  ) => {
    const result = (await editAppCampaignAd({
      ...values,
      campaign: campaignId,
    })) as { data: any }
    if (result.data) {
      setShowAlert(true)
      setTimeout(() => setShowAlert(false), 10000)
    } else {
      // @ts-ignore
      const message = result.error || t('somethingWentWrong')
      setStatus({ success: false })
      setErrors({ submit: message })
      setSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{tpagetexts('appPageSubtitle')}</h1>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <FairytaleFormComponent
                  formProps={CREATE_ADS_FORM_ATTRIB_DATA}
                  editMode={false}
                  onSubmit={_handleSubmit}
                  initialFormValues={ad}
                  formSchema={AD_SCHEMA}
                />
                <FairyTaleAlert
                  variant="success"
                  message={tforms('campaignUpdateSuccess')}
                  heading=""
                  show={showAlert}
                  name="edit-campaign"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}
