const common_en = {
  //Generic
  for: 'for',
  thereAre: 'There are',
  page: 'Page',
  show: 'Show',
  goToPage: 'Go to page:',
  submit: 'Submit',
  select: '--- Select ---',
  Actions: 'Actions',
  of: 'of',
  yes: 'Yes',
  no: 'No',
  cancel: 'Cancel',
  enabled: 'Enabled',
  disabled: 'Disabled',
  active: 'active',
  inActive: 'Inactive',
  updated: 'Updated',
}

export default common_en
