import { Button } from 'react-bootstrap'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Check, Copy } from 'react-feather'
import {
  useAddGlobalAccessTokenMutation,
  useGetGlobalAccessTokenQuery,
} from '../../redux/appQuery'
import { useParams } from 'react-router-dom'
import Loader from '../../components/Loader'
import useClipboard from 'react-use-clipboard'

export default function GlobalAccessToken() {
  const { appID } = useParams()
  const { data, isLoading } = useGetGlobalAccessTokenQuery()
  const [addToken] = useAddGlobalAccessTokenMutation()
  const [isCopied, setCopied] = useClipboard(data?.token, {
    successDuration: 1000,
  })
  if (isLoading) return <Loader />

  return (
    <Container fluid className="p-0">
      <h3 className="h3 mb-3">Token</h3>
      <Row className="mb-3">
        <Col>
          <Card>
            <Card.Body>
              {data?.token ? (
                <Row>
                  <div className="mb-3">{data.token}</div>
                  <div>
                    <Button onClick={setCopied}>
                      {isCopied ? (
                        <>
                          <Check size={16} /> Copied! 👍
                        </>
                      ) : (
                        <>
                          <Copy size={16} /> Copy Token
                        </>
                      )}
                    </Button>
                  </div>
                </Row>
              ) : (
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => addToken(appID)}
                >
                  Generate New Token
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
