import { useEffect, useState } from 'react'
import { Navbar, Nav } from 'react-bootstrap'

import useSidebar from '../../hooks/useSidebar'

import NavbarLanguages from './NavbarLanguages'
import NavbarUser from './NavbarUser'

import useAuth from '../../hooks/useAuth'
import { useGetUserByIdQuery } from '../../redux/appQuery'
import { getUserIdFromToken } from '../../utils/jwt'

const NavbarComponent = () => {
  const { isOpen, setIsOpen } = useSidebar()
  const { signOut } = useAuth()
  const userId = getUserIdFromToken()
  const { data: authenticatedUser, isLoading } = useGetUserByIdQuery(userId)
  const [isUser, setIsUser] = useState(
    authenticatedUser?.role?.role_name === 'user',
  )

  useEffect(() => {
    setIsUser(authenticatedUser?.role?.role_name === 'user')
  }, [authenticatedUser])

  return (
    <Navbar variant="light" expand className="navbar-bg">
      {!isUser && (
        <span
          className="sidebar-toggle d-flex"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          <i className="hamburger align-self-center" />
        </span>
      )}

      <Navbar.Collapse>
        <Nav className="navbar-align">
          <NavbarLanguages />
          <NavbarUser
            authenticatedUser={isLoading ? null : authenticatedUser}
            isLoading={isLoading}
            signOut={signOut}
          />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default NavbarComponent
